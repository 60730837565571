// Migrated
<template>
  <button
    id="charterButtonDestination"
    ref="charterButtonDestination"
    class="charter-search__btn"
    :class="{ active: isActive }"
    :aria-expanded="isActive"
    aria-haspopup="true"
    aria-labelledby="charterButtonLabelDestination"
    @click="$emit('click')"
  >
    <div
      id="charterButtonLabelDestination"
      class="charter-search__btn-title flex w-full"
    >
      <fa
        v-if="!$isSol"
        class="mr-2"
        :icon="icons.faMapLocationDot"
      />
      <span>
        {{ $t('charterSearchDestination') }}
      </span>
      <fa
        v-if="$isSol"
        class="charter-search__btn-icon ml-auto transition-transform"
        icon="chevron-down"
        :style="{ transform: `rotateX(${isActive ? '180deg' : '0'})` }"
      />
    </div>
    <div class="charter-search__btn-text">
      <span v-if="!stagedDestinationsForAirport.length">
        {{ $t('charterSearchSelect') }}
      </span>
      <span v-else>
        {{ displayDestinations }}
      </span>
      <fa
        v-if="!$isSol"
        class="charter-search__btn-icon"
        :icon="isActive ? 'caret-square-up' : 'caret-square-down'"
      />
    </div>
    <div
      v-if="selectDestinationFirst"
      class="charter-search__btn-title text-error blink blink-title"
    >
      {{ $t('charterSelectDestinationFirst') }}
    </div>
  </button>
</template>

<script>
import { mapState } from 'pinia'
import { faMapLocationDot } from '@fortawesome/pro-duotone-svg-icons'

export default defineNuxtComponent({
  name: 'PackageSearchDestinationButton',

  props: {
    isActive: {
      type: Boolean,
      required: true,
    },

    selectDestinationFirst: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['click'],

  computed: {
    ...mapState(useCharterPackagesStore, [
      'destinations',
      'airportDestinationIds',
    ]),

    ...mapState(useSearchWidgetsStore, {
      stagedDestinations: state => state.destinations.stagedDestinations,
      stagedCategories: state => state.destinations.stagedCategories,
    }),

    ...mapState(useSearchWidgetsStore, [
      'stagedDestinationsL1',
      'stagedDestinationsL1ForAirport',
      'stagedDestinationsForAirport',
    ]),

    displayDestinations () {
      if (this.stagedDestinations && this.stagedDestinations.length === this.destinations.length) {
        return this.$t('charterSearchDestinationsAll')
      } else if (this.stagedCategories && this.stagedCategories.length) {
        const categoryCount = this.stagedCategories.length - 1
        return `${categoryCount > 0 ? this.stagedCategories[0].name : this.stagedCategories[0]?.name}${categoryCount > 0 ? ` +${categoryCount}` : ''}`
      }

      let destinations = [...this.stagedDestinationsForAirport]

      const countries = []

      this.stagedDestinationsL1ForAirport.forEach((countryName) => {
        countries.push(countryName)

        destinations = destinations.filter(d => d.country !== countryName)
      })

      const destinationCount = destinations.length + countries.length - 1

      return `${countries.length > 0 ? countries[0] : destinations[0]?.name}${destinationCount > 0 ? ` +${destinationCount}` : ''}`
    },

    icons () {
      return {
        faMapLocationDot,
      }
    },
  },
})
</script>
