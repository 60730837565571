// Migrated
<template>
  <button
    id="charterButtonAirport"
    ref="charterButtonAirport"
    class="charter-search__btn"
    :class="{ active: isActive }"
    :aria-expanded="isActive"
    aria-haspopup="true"
    aria-labelledby="charterButtonLabelAirport"
    @click="$emit('click')"
  >
    <div
      id="charterButtonLabelAirport"
      class="charter-search__btn-title flex w-full"
    >
      <fa
        v-if="!$isSol"
        class="mr-2"
        :icon="icons.faPlaneDeparture"
      />
      <span>
        {{ $t('charterSearchDeparture') }}
      </span>
      <fa
        v-if="$isSol"
        class="charter-search__btn-icon ml-auto transition-transform"
        icon="chevron-down"
        :style="{ transform: `rotateX(${isActive ? '180deg' : '0'})` }"
      />
    </div>
    <div class="charter-search__btn-text">
      <span v-if="airportNotSet">
        {{ $t('charterSearchSelect') }}
      </span>
      <span v-else>
        {{ airportName }}
      </span>
      <fa
        v-if="!$isSol"
        class="charter-search__btn-icon"
        :icon="isActive ? 'caret-square-up' : 'caret-square-down'"
      />
    </div>
    <div
      v-if="selectAirportFirst"
      class="charter-search__btn-title text-error blink blink-title"
    >
      {{ $t('charterSelectAirportFirst') }}
    </div>
  </button>
</template>

<script>
import { faPlaneDeparture } from '@fortawesome/pro-duotone-svg-icons'

export default defineNuxtComponent({
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },

    selectedAirport: {
      type: Object,
      default: null,
    },

    selectAirportFirst: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click'],

  data () {
    return {
      icons: {
        faPlaneDeparture,
      },
    }
  },

  computed: {
    airportNotSet () {
      return !this.selectedAirport || !this.selectedAirport?.airport_name
    },

    airportName () {
      return this.selectedAirport?.airport_name
    },
  },
})
</script>
